import { baseAxios } from "../index";

export async function getAllPengajuan(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webPengajuan/getPengajuanByLogin?status=${data.status}&page=${data.page}&limit=${data.limit}&cari=${data.cari}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALL_PENGAJUAN", data: response.data });
    // console.log(response, "OOO");
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getAllPengajuanBestada(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `WebBestada/getKalsel?status=${data.status}&page=${data.page}&limit=${data.limit}&cari=${data.cari}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALL_PENGAJUAN_BESTADA", data: response.data });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getPengajuanByIdPengajuan(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webPengajuan/getPengajuanByIdPengajuan?idPengajuan=${data.idPengajuan}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    // dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
    dispatch({ type: "SET_ALL_PENGAJUAN_BY_ID", data: response.data.data[0] });
    // console.log(response, "MAS DHEAL");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getBestadaByIdPengajuan(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webBestada/getKalselByIdPengajuan?idPengajuan=${data.idPengajuan}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    // dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
    dispatch({
      type: "SET_ALL_PENGAJUAN_BESTADA_BY_ID",
      data: response.data.data[0],
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllPencairan(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webPengajuan/getPengajuanByLogin?status=${data.status}&page=${data.page}&limit=${data.limit}&cari=${data.cari}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_PENCAIRAN", data: response.data });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getTotalPencairan(dispatch, data, history) {
  try {
    const response = await baseAxios.post(
      "webDashboard/getTotalPencairan",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({
      type: "SET_ALL_TOTAL_PENCAIRAN",
      data: response.data.totalNominal,
    });
    // console.log(response, "PPPP");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getTotalTargetUser(dispatch, data, history) {
  try {
    const response = await baseAxios.post(
      "webDashboard/getTargetUserLogin",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({
      type: "SET_ALL_TOTAL_USER",
      data: response.data,
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function revisiPengajuan(data) {
  try {
    const response = await baseAxios.post(
      "webPengajuan/revisiPengajuanDariAtasan",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function pengajuanKeKoordinator(data) {
  try {
    const response = await baseAxios.post("webPengajuan/ajukanKeKoor", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function pengajuanUlangDokumen(data) {
  try {
    const response = await baseAxios.post(
      "webApiBpd/pengajuanUlangDokumen",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function cekStatusPengajuan(data) {
  try {
    const response = await baseAxios.post("webApiBpd/cekStatusManual", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function updateStatusManual(data) {
  try {
    const response = await baseAxios.post(
      "webApiBpd/updateStatusManual",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function pencairanAll(dispatch, data, history) {
  try {
    const response = await baseAxios.post(
      "webPengajuan/pencairanAllMarketing",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    dispatch({ type: "SET_PENCAIRAN_ALL", data: response.data.data });
    dispatch({ type: "SET_LIMIT_PAGE", data: response.data });

    // console.log(response, "RESPONSE");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function pencairanAllSpv(dispatch, data, history) {
  try {
    const response = await baseAxios.post(
      "webPengajuan/pencairanAllSupervisor",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    dispatch({ type: "SET_PENCAIRAN_ALL_SPV", data: response.data.data });
    dispatch({ type: "SET_LIMIT_PAGE_SPV", data: response.data });

    // console.log(response, "RESPONSE");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function pencairanAllAM(dispatch, data, history) {
  // console.log(data, "DATAA");
  try {
    const response = await baseAxios.post(
      "webPengajuan/pencairanAllAreaManager",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    dispatch({ type: "SET_PENCAIRAN_ALL_AM", data: response.data.data });
    dispatch({ type: "SET_LIMIT_PAGE_AM", data: response.data });

    // console.log(response, "RESPONSE");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function showUrlDokumen(dispatch, data, history) {
  try {
    const response = await baseAxios.post("webPengajuan/showUrlDokumen", data, {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({
      type: "SHOW_URL_DOKUMEN",
      data: response.data.url,
    });
    return response;
  } catch (err) {
    return err.response;
  }
}
